import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'validate.js';

import BuyerAuthModal from 'dpl/buyer_account/components/BuyerAuthModal';
import Icon from 'dpl/common/components/Icon';
import useCurrentUser from 'dpl/common/hooks/useCurrentUser';
import { PORTAL_MOUNT_EL } from 'dpl/common/tooltip/components/FloatingTooltipWrapper';
import SmartLink from 'dpl/components/SmartLink';
import {
  ANALYTICS_EVENTS,
  ANALYTICS_USER_JOURNEYS,
  ANALYTICS_VIEW_FEATURES
} from 'dpl/constants/analytics';
import { sendRudderstackEvent } from 'dpl/util/analytics';

import useUserFollowsData from '../hooks/useUserFollowsData';
import {
  FOLLOWABLE_STATUSES,
  FOLLOWABLE_STATUS_MESSAGE,
  FOLLOWABLE_TYPES
} from '../utils/constants';
import UserFollowSuccessPopover from './UserFollowSuccessPopover';

const DEFAULT_ICON_SIZE = '24px';

const DEFAULT_ICON_PROPS = {
  CHECKED: {
    name: 'fetch-favorite-fill',
    className: 'rubber-dark-mid',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  },
  UNCHECKED: {
    name: 'fetch-favorite-fill',
    className: 'default-color o-50',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  }
};

export default function FavoriteButton({
  className,
  followableTypeId,
  isFavorite: initialIsFavorite,
  followableType,
  analytics,
  targetEl,
  portalStyle,
  onChange,
  checkedIconProps,
  uncheckedIconProps
}) {
  const [showSuccessPopover, setShowSuccessPopover] = useState(false);
  const [popoverStatus, setPopoverStatus] = useState(null);
  const [showLoginModal, setShowLoginModal] = useState(false);

  const [isFavorite, setIsFavorite] = useState(initialIsFavorite);

  const iconProps = isFavorite
    ? { ...DEFAULT_ICON_PROPS.CHECKED, ...checkedIconProps }
    : { ...DEFAULT_ICON_PROPS.UNCHECKED, ...uncheckedIconProps };

  const {
    createUserFollow,
    removeUserFollow,
    userFollowsData,
    hasUserFollow: userFollow,
    isLoading,
    isUpdating
  } = useUserFollowsData(followableTypeId, {
    followable_type: followableType
  });

  const currentUser = useCurrentUser();
  const { isLoggedIn, value: currentUserData } = currentUser;

  function showPopover(status) {
    setPopoverStatus(status);
    setShowSuccessPopover(true);
  }

  useEffect(() => {
    let timeout;
    if (showSuccessPopover) {
      timeout = setTimeout(() => setShowSuccessPopover(false), 3000);
    }
    return () => clearTimeout(timeout);
  }, [showSuccessPopover]);

  useEffect(() => {
    if (!isLoading) setIsFavorite(!isEmpty(userFollow));
  }, [isLoading, userFollow]);

  async function updateFavorite(buyerProfileID) {
    if (!isFavorite) {
      const eventName =
        followableType === FOLLOWABLE_TYPES.DOG
          ? ANALYTICS_EVENTS.PUPPY_ADDED_TO_FAVORITES
          : ANALYTICS_EVENTS.BREEDER_ADDED_TO_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id:
          currentUserData.data?.buyer_profile_id || buyerProfileID,
        view_feature: ANALYTICS_VIEW_FEATURES.FAVORITE_HEART_ICON,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING,
        ...analytics
      });

      try {
        await createUserFollow({
          body: {
            followable_type: followableType,
            followable_id: followableTypeId
          }
        });
      } finally {
        await userFollowsData.refetch();
        showPopover(FOLLOWABLE_STATUS_MESSAGE.ADDED_TO_FAVORITES);
        onChange?.({ status: FOLLOWABLE_STATUSES.ADDED, followableTypeId });
      }
    } else {
      const eventName =
        followableType === FOLLOWABLE_TYPES.DOG
          ? ANALYTICS_EVENTS.PUPPY_REMOVED_FROM_FAVORITES
          : ANALYTICS_EVENTS.BREEDER_REMOVED_FROM_FAVORITES;

      sendRudderstackEvent(eventName, {
        buyer_profile_id: currentUserData.data.buyer_profile_id,
        view_feature: ANALYTICS_VIEW_FEATURES.FAVORITE_HEART_ICON,
        breeder_profile_id: analytics.breeder_profile_id,
        breeder_name: analytics.breeder_name,
        user_journey: ANALYTICS_USER_JOURNEYS.BUYER_BROWSING,
        ...analytics
      });

      try {
        await removeUserFollow();
      } finally {
        await userFollowsData.refetch();
        showPopover(FOLLOWABLE_STATUS_MESSAGE.REMOVED_FROM_FAVORITES);
        onChange?.({ status: FOLLOWABLE_STATUSES.REMOVED, followableTypeId });
      }
    }
  }

  async function handleUserLogin() {
    setShowLoginModal(false);
    const { data: loggedInUser } = await currentUser.refetch();
    if (loggedInUser?.data?.data?.buyer) {
      const { data: userFollows } = await userFollowsData.refetch();
      const userAlreadyFollows = userFollows?.data?.data?.some(
        f => f.followable_id === followableTypeId
      );
      if (!userAlreadyFollows) {
        const buyerProfileID = loggedInUser.data.data.buyer_profile_id;
        updateFavorite(buyerProfileID);
      }
    }
  }

  function handleFavoriteClick(e) {
    e.stopPropagation();
    e.preventDefault();

    if (!isLoggedIn) {
      setShowLoginModal(true);
    } else {
      updateFavorite();
    }
  }

  const popoverMessage =
    popoverStatus === FOLLOWABLE_STATUS_MESSAGE.REMOVED_FROM_FAVORITES ? (
      `${popoverStatus} favorites`
    ) : (
      <span>
        {popoverStatus} your{' '}
        <SmartLink to="/account/favorites" className=" underline">
          favorites
        </SmartLink>
      </span>
    );

  return (
    <>
      <UserFollowSuccessPopover
        title={popoverMessage}
        isVisible={showSuccessPopover}
        targetEl={targetEl}
        portalStyle={portalStyle}
      >
        <button
          type="button"
          className={classnames('flex items-center justify-center', className)}
          onClick={handleFavoriteClick}
          disabled={isLoading || isUpdating}
        >
          <Icon {...iconProps} />
          {iconProps.label && <div className="ml2">{iconProps.label}</div>}
        </button>
      </UserFollowSuccessPopover>
      {showLoginModal && (
        <BuyerAuthModal
          onCloseClick={() => setShowLoginModal(false)}
          onSuccessfulAuth={handleUserLogin}
        />
      )}
    </>
  );
}

FavoriteButton.propTypes = {
  analytics: PropTypes.shape({
    breeder_profile_id: PropTypes.number,
    breeder_name: PropTypes.string,
    breed_id: PropTypes.number,
    page_number: PropTypes.number,
    position: PropTypes.number,
    puppies_available: PropTypes.number,
    transportation: PropTypes.arrayOf(PropTypes.string),
    view: PropTypes.string
  }),
  className: PropTypes.string,
  followableTypeId: PropTypes.number.isRequired,
  isFavorite: PropTypes.bool,
  followableType: PropTypes.oneOf(Object.values(FOLLOWABLE_TYPES)).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  targetEl: PropTypes.any,
  portalStyle: PropTypes.object,
  onChange: PropTypes.func,
  checkedIconProps: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
  }),
  uncheckedIconProps: PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    className: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string
  })
};

FavoriteButton.defaultProps = {
  analytics: {},
  className: null,
  isFavorite: false,
  targetEl: PORTAL_MOUNT_EL,
  portalStyle: null,
  onChange: null,
  checkedIconProps: {
    name: 'fetch-favorite-fill',
    label: null,
    className: 'rubber-dark-mid',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  },
  uncheckedIconProps: {
    name: 'fetch-favorite-fill',
    label: null,
    className: 'default-color o-50',
    height: DEFAULT_ICON_SIZE,
    width: DEFAULT_ICON_SIZE
  }
};
